import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Questions from './Questions';
import * as serviceWorker from './serviceWorker';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from "react-apollo";
import { InMemoryCache } from 'apollo-cache-inmemory';
import { persistCache } from 'apollo-cache-persist';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route } from "react-router-dom";

ReactGA.initialize('UA-145020111-1');
ReactGA.set({ anonymizeIp: true });
ReactGA.pageview('/');

class AppApolloClient extends Component {
  state = {
    client: null,
    loaded: false,
  };

  async componentDidMount() {
    const cache = new InMemoryCache();
    const client = new ApolloClient({
      cache,
      uri: '/.netlify/functions/graphql',
    });

    cache.writeData({ data: {search: [], version: 1.0}});

    try {
      await persistCache({
        cache,
        storage: window.localStorage,
      });
    } catch (error) {
      console.error('Error restoring Apollo cache', error);
    }

    this.setState({
      client,
      loaded: true,
    });
  }

  render() {
    const { client, loaded } = this.state;

    if (!loaded) {
      return <div>Loading</div>;
    }

    return (
      <ApolloProvider client={client}>
        <Router>
          <Route path="/" exact component={App} />
          <Route path="/questions" exact component={Questions} />
        </Router>
      </ApolloProvider>
    );
  }
}

ReactDOM.render(<AppApolloClient />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
