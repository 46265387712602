import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Logo } from '../assets/logo.svg';
import IconButton from '@material-ui/core/IconButton';
import { Link as RouterLink} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import QuestionAnswer from '@material-ui/icons/QuestionAnswer';


export default function Title() {

  return (
    <div>
      <AppBar position="static" color="primary">
        <Toolbar onClick={window.reload}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center">
          <Grid item>
          <IconButton edge="start" color="inherit">
            <RouterLink to='/' style={{ textDecoration: 'none', color: 'white'}}>
              <Typography variant="h4" color="inherit">
                <span className='title-font'>
                  Check the Mass H
                  <Logo fill="white" style={{width: '30px', height:'30px'}}/>
                  le
                </span>
              </Typography>
            </RouterLink>
          </IconButton>
          </Grid>
          <Grid item>
            <RouterLink to='/questions' style={{ textDecoration: 'none', color: 'white'}}>
              <Typography variant="h6" color="inherit">
                <QuestionAnswer/> Questions?
              </Typography>
            </RouterLink>
          </Grid>
        </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}