import React, { Component } from "react";
import MaterialTable from "material-table";
import ReactGA from 'react-ga';

class PlumbingTable extends Component {
  render() {
    return (
      <MaterialTable
      columns={[
        {
          title: "Manufacturer",
          field: "manufacturer",
        },
        {
          title: "Model",
          field: "model",
        },
        {
          title: "Product",
          field: "product"
        },
        {
          title: "Approval Code",
          field: "approval_code"
        },
        {
          title: "Accepted",
          field: "accepted"
        },
        ]}
        data={this.props.products}
        actions={[{
          icon: 'find_in_page',
          iconProps: {color: "primary"},
          tooltip: 'Google product in a new tab',
          onClick: (event, rowData) => {
            ReactGA.event({
              category: 'UserAction',
              action: 'GoogleItem',
              label: rowData.model
            });
            window.open("https://www.google.com/search?q=" + rowData.manufacturer + " " + rowData.model + " " + rowData.product)
          }
        }]}
        options={{
          exportButton: false,
          pageSize: 20,
          filtering: true,
          search: false,
          pageSizeOptions: [20, 50, 100, 250]
        }}
        title=""
        />
        )
  }
}

export default PlumbingTable;