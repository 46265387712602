import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Title from './components/Title';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { DisclaimerText } from './components/Disclaimer';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '600px',
    margin: 'auto',
  },
}));

export default function Questions() {
  const classes = useStyles();

  return (
      <div>
        <Title/>
        <br/>
        <List component="nav" className={classes.root} aria-label="mailbox folders">
          <ListItem>
            <Typography className={classes.root} variant="h5" color="inherit">
              Commonly Asked Questions
            </Typography>
          </ListItem>

          <ListItem>
            <ListItemText primary="What was the disclaimer again?" secondary={<DisclaimerText/>}/>
          </ListItem>
          <Divider light/>

          <ListItem>
            <ListItemText primary="What is the motivation behind the site?" secondary="This site was created out of our personal need to find Mass approved plumbing during our own renovation. My girlfriend and I were finding a number of tubs and vanities on Houzz or Wayfair and had to consistently check if it was mass approved/mass coded/mass certified. Out of frustration with the user experience with the official site, I created a prototype of this app to help us efficiently verify whether a specific plumbing product is mass-approved or not."/>
          </ListItem>
          <Divider light/>

          <ListItem>
            <ListItemText primary="How did you come up with this name?" secondary="You can thank my girlfriend's sister for this great idea. We think it is a funny way to express the purpose of the site while expressing our frustration that plumbing has to be mass-approved in Massachusetts."/>
          </ListItem>
          <Divider light/>

          <ListItem>
            <ListItemText primary="How does this database compare to the official database?" secondary={<span>This database was updated on July 23, 2019. It is missing roughly 150 products due a bug with the official website that I cannot get around. If you discover a product that is reported on the official site and not on this one, please send an email to <a href="mailto:hello@checkthemasshole.com">hello@checkthemasshole.com</a>.</span>}/>
          </ListItem>
          <Divider light/>

          <ListItem>
            <ListItemText primary="What guarantees that this site will stay up?" secondary="There is no promise on the availability or performance of this site. Ultimately, it costs money to keep it running and as long as I can keep it going for a reasonable rate and enough people find it useful, I will try my best to keep it up. It is currently on a lower tier, so performance is not optimal, but hey, it's working and is quite performant after the initial search."/>
          </ListItem>
          <Divider light/>

          <ListItem>
            <ListItemText primary="What information are you collecting about me?" secondary="This site integrates with Google Analytics to measure how often it is visited and to capture key interaction events. The information is completely anonymized and is used to improve the site. If this concerns you, you can get a browser ad-blocker like uBlock to block sharing this information from this site and others like it."/>
          </ListItem>
          <Divider light/>

          <ListItem>
            <ListItemText primary="What if I have additional questions?" secondary={<span>Great! You can send an email to <a href="mailto:hello@checkthemasshole.com">hello@checkthemasshole.com</a> and I will respond as soon as I can.</span>}/>
          </ListItem>
          <Divider light/>

          <ListItem>
            <ListItemText primary="What if I have a feature suggestion?" secondary={<span>I would love to hear it. No promised on implementing it though. You can send your ideas to <a href="mailto:hello@checkthemasshole.com">hello@checkthemasshole.com</a>.</span>}/>
          </ListItem>
          <Divider light/>

          <ListItem>
            <ListItemText primary="What if I discover a bug?" secondary={<span>You can report it! Shoot me an email describing the issue to <a href="mailto:ohno@checkthemasshole.com">ohno@checkthemasshole.com</a>. It is most helpful if you include your browser information, what you expected, and what you experienced instead.</span>}/>
          </ListItem>
          <Divider light/>
        </List>
      </div>
  );
}