import React, { Component } from 'react'
import { withApollo } from 'react-apollo'
import gql from 'graphql-tag'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Search from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import ReactGA from 'react-ga';
import BarLoader from 'react-spinners/BarLoader';
import Tooltip from '@material-ui/core/Tooltip';

const SEARCH_QUERY = gql`
    query search($filter: String) {
      search (filter: $filter){
        id
        manufacturer
        model
        product
        accepted
        approval_code
      }
    }
    `
const tooFewChars = "You need more than 3 characters to initiate the search."

class SearchInput extends Component {
  constructor(props) {
      super(props);
      this.keyPress = this.keyPress.bind(this);
   }

  state = {
    filter: '',
    isError: false,
    helperText: "",
    searching: false
  }

  keyPress(e){
    if(e.keyCode === 13){
      this._executeSearch();
    }
   }

  render() {
    return (
      <div style={{padding: '10px'}}>
        <Grid container spacing={2} direction="row" alignItems="center">
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Tooltip title={(<span className="title-font">Press 'enter' to initiate search. This search will find matching text for the manufacturer, model, or product. You can further narrow your search using the table column filters. You can wrap search terms in double quotes to match exact phrase.</span>)} enterDelay={3000}>
              <TextField
                error={this.state.isError}
                id='search-input'
                margin="normal"
                autoFocus
                type='text'
                helperText={this.state.helperText}
                fullWidth
                placeholder="Search for plumbing products"
                onKeyDown={this.keyPress}
                onChange={e => this.setState({ filter: e.target.value })}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={3}>
            <Button color='primary' size='large' variant='outlined' onClick={e => this._executeSearch()}><Search/></Button>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            {this.state.searching ?
              (<BarLoader width={100} widthUnit={"%"} color={'#3f51b5'}/>)
              : null}
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      </div>
    )
  }

  _executeSearch = async () => {
    const { filter } = this.state

    if (filter.length < 3){
      this.setState({isError: true, helperText: tooFewChars, searching: false})
      return;
    }

    this.setState({isError: false, helperText: "", searching: true})
    this.props.client.writeData({ data: {searchStatus: true}})
    ReactGA.event({
      category: 'UserAction',
      action: 'Search',
      label: filter
    });
    const result = await this.props.client.query({
      query: SEARCH_QUERY,
      variables: { filter },
    })
    const searchResults = result.data.search
    this.setState({searching: false})
    this.props.client.writeData({ data: { search: searchResults, searchStatus: false} })
  }
}

export default withApollo(SearchInput)