import React from "react";
import "./index.css";
import { Query } from "react-apollo";
import { gql } from "apollo-boost";
import SearchInput from './components/SearchInput';
import PlumbingTable from './components/PlumbingTable';
import { Disclaimer } from './components/Disclaimer';
import Title from './components/Title';
import Typography from '@material-ui/core/Typography';

const SEARCH_QUERY = gql`
{
  plumbing: search @client {
      id
      manufacturer
      model
      product
      approval_code
      accepted
    }
  }
`;

const App = () => (
  <Query query={SEARCH_QUERY}>
  {({data: { plumbing, searchStatus }, client }) => (
      <div>
        <Title/>
        <Disclaimer/>
        <SearchInput/>
        <PlumbingTable products={plumbing}/>
        <br/>
        <br/>
        <br/>
        <Typography variant="overline" display="block" gutterBottom>
          Logo made by <a href="https://www.flaticon.com/authors/good-ware" title="Good Ware">Good Ware</a> from <a href="https://www.flaticon.com/" title="Flaticon">Flaticon</a>
        </Typography>
      </div>
    )}
  </Query>
  );

export default App;