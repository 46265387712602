import React, { Component } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ReactGA from 'react-ga';

class DisclaimerText extends Component {
  render (){
    return (
      <span style={{lineHeight: '150%'}}>
        <b>Disclaimer:</b> ChecktheMassHole.com is a home-grown project that seeks to improve the searchability of Massachusetts approved plumbing products. The information provided within this site is periodically updated with the official mass approved database. This site aims to be useful to contractors, home owners, architects, plumbers, bathroom designers, and others in the plumbing industry working on Massachusetts projects. This site is <b>NOT</b> <a href="https://licensing.reg.state.ma.us/pubLic/pl_products/pb_product.asp">the official site</a> for checking Massachusetts approved plumbing nor is it affliated with that site or the Massachusetts public office. If you have doubts or concerns about the data on this site, please use <a href="https://licensing.reg.state.ma.us/pubLic/pl_products/pb_product.asp">the official site</a>. By continuing to use this site, you agree that you have read this disclaimer and you accept the consequences for how you use the data on this site.
      </span>
    )
  }
}

class Disclaimer extends Component {
  constructor(props) {
      super(props);
      this.onClick = this.onClick.bind(this);
      this.state = {acceptDisclaimer: window.localStorage.getItem('acceptDisclaimer')}
  }

  onClick(){
    window.localStorage.setItem('acceptDisclaimer', true)
    ReactGA.event({
      category: 'UserAction',
      action: 'Close',
      label: 'DisclaimerWindow'
    });
    this.setState({acceptDisclaimer: true})
  }

  render() {
    if (this.state.acceptDisclaimer)
      return (<div/>);
    else
      return (
        <div style={{padding: '10px', margin: '10px'}}>
          <Paper style={{padding: '10px', margin: '10px'}}>
            <Grid container justify="center" alignItems="flex-start" direction="column" spacing={4}>
              <Grid item>
                <DisclaimerText/>
              </Grid>
              <Grid item>
                <Button color='primary' size='medium' variant='contained' onClick={this.onClick}>Got it!</Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      );
  }
}


export {Disclaimer, DisclaimerText}